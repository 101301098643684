import React, {useEffect, useState} from "react";
import ProviderService from "../../../services/ProviderService";
import "./IdentifyPlateComponent.css";
import TowTruckService from "../../../services/TowTruckService";
import {useFormik} from "formik";
import ErrorService from "../../../services/ErrorService";
import {errorToast, successToast} from "../../../_helpers/ToastMessages";
import Constants from "../../../util/constantStrings";
import { useTranslation } from "react-i18next";
function IdentifyPlateComponent(){
    const { t } = useTranslation();
    const [providerList, setProviderList] = useState([])
    const [toastMessage, setToastMessage] = useState("");
    const [showSuccessToastMessage, setSuccessShowToastMessage] = useState(false);
    const [showErrorToastMessage, setErrorShowToastMessage] = useState(false);
    function saveNewPlateAndImei(payload){
        TowTruckService.postTowTruckAgents(payload).then(()=> {
            setToastMessage(t(Constants.PLATE_IDENTIFY_SUCCESS_MESSAGE));
            setSuccessShowToastMessage(true);
            setInterval(() => {
                setSuccessShowToastMessage(false);
            }, 4000)
        }).catch(error => {
            setToastMessage(error.response.data.message ?? t(Constants.PLATE_IDENTIFY_ERROR_MESSAGE));
            setErrorShowToastMessage(true);
            setInterval(() => {
                setErrorShowToastMessage(false);
            }, 4000)
            ErrorService.errorConsole(error)
        });
    }
    const formik = useFormik({
        initialValues: {
            plate: "",
            imei: "",
            provider: "",
            mobileNumber: ""
        },
        onSubmit: (values) => {
                const payload = {
                    plateNumber: values.plate,
                    imei: values.imei,
                    providerId: Number(values.provider),
                    mobileNumber: values.mobileNumber
                }
            saveNewPlateAndImei(payload);
        },
    });
    useEffect(()=>{
        ProviderService.getProviderList(0).then(response=>setProviderList(response.data.data.providerListItemDtos)).catch(error => ErrorService.errorConsole(error))
    },[])
    return (
            <div className="row mt-lg-4">
                {showSuccessToastMessage && successToast(toastMessage)}
                {showErrorToastMessage && errorToast(toastMessage)}
                <div className="col-12">
                    <div className="border-white rounded shadow bg-white rounded padding-20 container container-height">
                        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                            <div className="form-group row mb-5">
                                <label htmlFor="provider" className="col-sm-2 col-form-label">{t('COLUMN.PROVIDER')}</label>
                                <div className="col-sm-10">
                                    <select
                                        id="provider"
                                        name="provider"
                                        className="form-select"
                                        onChange={formik.handleChange}
                                        value={formik.values.provider}
                                    >
                                        <option selected>Select</option>
                                        {providerList.map(providerItem => {
                                            return <option value={providerItem.pid}>{providerItem.pname}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row mb-5">
                                <label htmlFor="plate" className="col-sm-2 col-form-label">{t('LABEL.PLATE')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="plate" name="plate" placeholder={t('LABEL.PLATE')} onChange={formik.handleChange}
                                           value={formik.values.plate}/>
                                </div>
                            </div>
                            <div className="form-group row mb-5">
                                <label htmlFor="mobileNumber" className="col-sm-2 col-form-label">{t('LABEL.PHONE_NUMBER')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="mobileNumber" name="mobileNumber" placeholder={t('LABEL.PHONE_NUMBER')}  onChange={formik.handleChange}
                                           value={formik.values.mobileNumber}/>
                                </div>
                            </div>
                            <div className="form-group row mb-5">
                                <label htmlFor="imei" className="col-sm-2 col-form-label">{t('LABEL.DEVICE_ID')}</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="imei" name="imei" placeholder={t('LABEL.DEVICE_ID')}  onChange={formik.handleChange}
                                           value={formik.values.imei}/>
                                </div>
                            </div>
                            <div className="form-group row mb-5 float-end">
                                <button type="submit" className="btn btn-primary">{t('BUTTON.SAVE')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    )
}
export { IdentifyPlateComponent };