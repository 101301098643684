import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useUserActions } from "_actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axaLogo from "../../../assets/images/axaLogo.png";
import imgOne from "../../../assets/images/login-page-image-one.jpg";
import imgTwo from "../../../assets/images/login-page-image-two.jpg";
import imgThree from "../../../assets/images/login-page-image-three.jpg";
import "./LoginComponent.css";
import ConstantArray from "../../../util/constantArray"
function LoginComponent(props) {
    const { t } = useTranslation();
    const [loginPageImages, setLoginPageImages] = useState([
        imgOne,
        imgTwo,
        imgThree,
    ]);
    const [currentImageIndex, setCurrentImageIndex] = useState(
        Math.floor(Math.random() * loginPageImages.length)
    );
    const [language, setLanguage] = useState(navigator.language || navigator.userLanguage);
    const userActions = useUserActions();

    const validationSchema = Yup.object().shape({
        username: Yup.string().required("Username is required"),
        password: Yup.string().required("Password is required"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    useEffect(() => {
        setLanguage(window.localStorage.getItem('I18N_LANGUAGE'))

    }, [])
    return (
        <div className="login-container">
            <div
                className="login-image-area"
                style={{
                    backgroundImage: `url(${loginPageImages[currentImageIndex]})`,
                }}
            >
                <div className="auth-page-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 login-area">
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={axaLogo} alt="" height="75" width="75" />
                                        </Link>
                                    </div>
                                    <p
                                        className="mt-3 fs-15 fw-medium"
                                        style={{ color: "black" }}
                                    >
                                        {t('TEXT.LOGIN_PAGE_WELCOME')}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <div className="mt-4">
                                    <div className="login p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">{t('BUTTON.LOG_IN')}</h5>
                                        </div>
                                        <div
                                            className="p-2 mt-4"
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <form
                                                onSubmit={handleSubmit(userActions.login)}
                                                action="#"
                                            >
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">
                                                        {t('LABEL.USERNAME')}
                                                    </label>
                                                    <input
                                                        name="username"
                                                        type="text"
                                                        {...register("username")}
                                                        className={`form-control ${errors.username ? "is-invalid" : ""
                                                            }`}
                                                    />
                                                    <div className="invalid-feedback">
                                                        {errors.username?.message}
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label
                                                        className="form-label"
                                                        htmlFor="password-input"
                                                    >
                                                        {t('LABEL.PASSWORD')}
                                                    </label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <input
                                                            name="password"
                                                            type="password"
                                                            {...register("password")}
                                                            className={`input form-control ${errors.password ? "is-invalid" : ""
                                                                }`}
                                                        />
                                                        <div className="invalid-feedback">
                                                            {errors.password?.message}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <button
                                                        color="button success"
                                                        className="btn btn-success w-100"
                                                        type="submit"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting && (
                                                            <span className="spinner-border spinner-border-sm mr-1"></span>
                                                        )}{" "}
                                                        <h5 className="text-primary">{t('BUTTON.LOG_IN')}</h5>
                                                    </button>
                                                </div>
                                                <div className="mt-5 ms-5 me-5">
                                                    <select
                                                        id="language"
                                                        name="language"
                                                        className="form-select"
                                                        value={language}
                                                        onChange={(event) => {
                                                            setLanguage(event.target.value)
                                                            window.localStorage.setItem('I18N_LANGUAGE', event.target.value)
                                                            window.localStorage.setItem('i18nextLng', event.target.value)
                                                            window.location.reload();
                                                        }}
                                                    >
                                                        {ConstantArray.LANGUAGE.map(languageItem => {
                                                            return (
                                                                <option value={languageItem.value} key={languageItem.value}>{languageItem.text}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="mt-4 text-center"></div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export { LoginComponent };
