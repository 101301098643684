import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
} from "@react-google-maps/api";

import Constants from "../util/constantStrings";
import {useEffect, useState} from "react";
import Util from "../util/utilityFunctions/Util";
import ConstantStatusIcon from "../util/constantStatusIcon";
import {Spinner} from "./Spinner";
import ErrorService from "../services/ErrorService";
import { useTranslation } from "react-i18next";
const GoogleMaps = ({ towTruckLocationList, routeList, isLoading, showTruckLocation, setIsLoading, towTruckLocation, showStatusRoute, towTruckPlate, providerName, distanceMatrix, showDistanceMatrix, logoImage }) => {
    const { t } = useTranslation();
    const [directions, setDirections] = useState([]);
    const containerStyle = {
    width: "100wh",
    height: "400px",
  };
    function getIcon(locationItem) {
        if (showTruckLocation) {
            return ConstantStatusIcon.STATUS_ICON_MAP.get(0)
        }else {
            return ConstantStatusIcon.STATUS_ICON_MAP.get(locationItem.statusId);
        }
    }
    function sleep(retryCount){
        return new Promise(resolve => setTimeout(resolve, Math.pow(2, retryCount + 6 )));
    }
    async function requestGoogleMapApi(directionsService, requestPayload, retryCount = 0) {
        await directionsService.route(requestPayload).then((response) => {
            if(showStatusRoute) {
                setDirections([response]);
            } else {
                setDirections([...directions, response]);
            }

        }).catch(error => {
            ErrorService.errorConsole(error);
            if(error.code === Constants.GOOGLE_MAP_API_OVER_QUERY_LIMIT && retryCount < Constants.EXPONENTIAL_LIMIT) {
               sleep(retryCount).then(() => requestGoogleMapApi(directionsService, requestPayload, retryCount + 1));
            }
        })

    }
    function getGoogleMapApiDirections(origin,destination,directionsService,google){
        for (const [index,item] of routeList.entries()) {
            const requestPayload = {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                waypoints: item
            };
            setTimeout(()=> {
                requestGoogleMapApi(directionsService, requestPayload);
            },index * 20);
        }
        setIsLoading(false);
    }
    function googleMapApi(google){
        const directionsService = new google.maps.DirectionsService();
        const originLocationSet = Util.safeArrayElementAccess(routeList,Constants.ARRAY_ZERO_INDEX, []);
        const origin = Util.safeArrayElementAccess(originLocationSet,Constants.ARRAY_ZERO_INDEX, {})?.location;
        const destinationLocationSet = Util.getLastElementOfArray(routeList, []);
        const destination = Util.getLastElementOfArray(destinationLocationSet, {})?.location;
        getGoogleMapApiDirections(origin,destination,directionsService,google);
    }
    function generateColor(){
        return Math.random().toString(16).substr(-6);
    }
    function getPlateNumber(locationItem){
        if(locationItem && !Util.checkIsNullOrUndefined(locationItem.plateNumber)) {
            return locationItem.plateNumber.substring(Constants.DISPLAY_PLATE_SUBSTRING_COUNT);
        }else {
            return ""
        }
    }
    useEffect(()=> {
        setTimeout(()=> {
            if(!Util.checkIsNullOrUndefined(routeList)) {
                googleMapApi(window.google);
            } else {
                setIsLoading(false);
            }
        },3000);
  },[routeList,window]);
  return (
      <>
          {!isLoading ?
              (<>
                   {showDistanceMatrix && <div className="row mt-lg-4">
                      <div className="col-12">
                          <div className="border-white rounded shadow bg-white rounded padding-20">
                              <div className="row">
                                  <img src={logoImage} alt={'not found'} />
                              </div>
                              <div className="row">
                                  <p className="col service-detail-text">{t("LABEL.TOW_TRUCK_PLATE")} <strong>{towTruckPlate}</strong></p>
                                  <p className="col service-detail-text">{t("LABEL.PROVIDER_NAME")}<strong>{providerName}</strong></p>
                                  <p className="col service-detail-text">{t("LABEL.ESTIMATED_ATTIVAL_TIME")} <strong>{distanceMatrix?.duration}</strong></p>
                              </div>
                          </div>
                      </div>
                  </div>}
                  <GoogleMap
                    markers={routeList}
                    mapContainerStyle={containerStyle}
                    center={towTruckLocationList ? towTruckLocationList[0] : null}
                    zoom={Constants.GOOGLE_MAP_ZOOM}
                    >
                  {towTruckLocationList.map((locationItem, index) => {
                      return (
                          <div key={index}>
                          {((!showTruckLocation && locationItem?.statusId > 1) || (showTruckLocation && locationItem?.statusId >=0) ) &&
                              <Marker position={locationItem} icon={getIcon(locationItem)} title={getPlateNumber(locationItem)}/>}
                          </div>
                  )
                  })}
                  {directions && directions.map((item, index) => {
                      return (
                          <div key={index}>
                              <DirectionsRenderer  directions={item} options={{polylineOptions:{strokeColor: `#${generateColor()}`}, suppressMarkers: true}}/>
                          </div>
                      )
                  })}
                  </GoogleMap>
              </>) :
              <Spinner/>}
    </>
  );
};
export {GoogleMaps};
