import {GoogleMaps} from "../../../_helpers";
import { useParams, useLocation } from "react-router-dom";
import {useEffect, useState} from "react";
import Util from "../../../util/utilityFunctions/Util";
import TowTruckService from "../../../services/TowTruckService";
import CustomerService from "../../../services/CustomerService";
import {LoadScript} from "@react-google-maps/api";
import Alert from "../../../_helpers/Alerts";
import Constants from "../../../util/constantStrings";
import ErrorService from "../../../services/ErrorService";
import ActionNotification from "../../../_helpers/ActionNotification";
import "./TowTruckLocationComponent.css"
import { errorToast } from "../../../_helpers/ToastMessages";
import { useTranslation } from "react-i18next";
function TowTruckLocationComponent() {
    const { t } = useTranslation();
    let { guid } = useParams();
    const location = useLocation();
    const API_KEY = `${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
    const [towTruckLocationInfo, setTowTruckLocationInfo] = useState([]);
    const [customerLocationInfo, setCustomerLocationInfo] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowAlert, setIsShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [towTruckPlate, setTowTruckPlate] = useState("");
    const [providerName, setProviderName] = useState("");
    const [distanceMatrix, setDistanceMatrix] = useState({});
    const [showDistanceMatrix, setShowDistanceMatrix] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorToastMessage, setErrorShowToastMessage] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [logoImage, setLogoImage] = useState('');
    function durationConvert (second) {
        let sec_num = parseInt(second, 10);
        let hours   = Math.floor(sec_num / Constants.MILLISECOND_TIME_CONVERT_COEFFICIENT);
        let minutes = Math.floor((sec_num - (hours * Constants.MILLISECOND_TIME_CONVERT_COEFFICIENT)) / Constants.MINUTE_TIME_CONVERT_COEFFICIENT);
        if (hours > 0) {
            const hourKey = t('TIME.HOUR')
            const minKey = t('TIME.MINUTE')
            return `${hours} ${hourKey} ${minutes} ${minKey}`;
        } else {
            return `${minutes} Dakika`;
        }

    }
    function getDistanceMatrix(){
        if(!Util.checkIsNullOrUndefined(customerLocationInfo)) {
            const service = new window.google.maps.DistanceMatrixService();
            const matrixOptions = {
                origins: [{lat: towTruckLocationInfo[0].lat, lng: towTruckLocationInfo[0].lng}],
                destinations: [{
                    lat: Number(customerLocationInfo.geoCustLatitude),
                    lng: Number(customerLocationInfo.geoCustLongitude)
                }],
                travelMode: window.google.maps.TravelMode.DRIVING,
                unitSystem: window.google.maps.UnitSystem.METRIC
            };
            service.getDistanceMatrix(matrixOptions).then(response => {
                const distanceKm = parseFloat(response.rows[0].elements[0].distance.text.replace(/,/g, '.'));
                const durationWithSecond = (Constants.MATRIX_DURATION_COEFFICIENT * response.rows[0].elements[0].duration.value);
                const durationHourMinute = durationConvert(durationWithSecond);
                setDistanceMatrix({distance: distanceKm, duration: durationHourMinute});
                setShowDistanceMatrix(true);
            }).catch(error => {
                ErrorService.errorConsole(error);
            })
        }
    }
    function getUserLocation(){
        localStorage.setItem("serviceGuidNo",guid);
        navigator.geolocation.getCurrentPosition((position) => {
            const payload = {
                serviceGuid: guid,
                geoCustLatitude: position.coords.latitude.toString(),
                geoCustLongitude: position.coords.longitude.toString()
            }
            setCustomerLocationInfo(payload);
            CustomerService.postCustomerLocationInfo(payload).catch(error => {
                setToastMessage(error.response.data.message ?? t(Constants.PLATE_IDENTIFY_ERROR_MESSAGE));
                setErrorShowToastMessage(true);
                setInterval(() => {
                    setErrorShowToastMessage(false);
                }, 4000)
                ErrorService.errorConsole(error)
            });
        });
    }
    function getTowTruckLocation() {
        TowTruckService.getTowTruckLocationInfoByGuid(guid).then(response => {
            if (response.data.responseStatus === Constants.RESPONSE_STATUS_SUCCESS_STRING) {
                const locationInfo = {
                    lat: Number(response.data.data.latitude),
                    lng: Number(response.data.data.longitude),
                    plateNumber: response.data.data.plateNumber,
                    statusId: 2
                }
                setLogoImage(response.data.data.productImage)
                setTowTruckLocationInfo([locationInfo]);
                setTowTruckPlate(response.data.data.plateNumber);
                setProviderName(response.data.data.providerName)
                setAlertMessage("");
                setIsShowAlert(false);
            } else if(response.data.responseStatus === Constants.RESPONSE_STATUS_ERROR_STRING) {
                setAlertMessage(response.data.message);
                setIsShowAlert(true);
            }
        }).catch(error => ErrorService.errorConsole(error))
    }

    useEffect(()=>{
        if(!Util.checkIsNullOrUndefined(guid) && !Util.checkIsNullOrUndefined(guid)) {
            getTowTruckLocation();
        }
        if(localStorage.getItem("serviceGuidNo") === guid){
            setShowNotification(false);
            getUserLocation();
            setShowNotification(false)
        } else {
            const pathname = location.pathname;
            const parts = pathname.split('/');
            const extractedValue = parts[1];
            if (extractedValue === 'cl') {
                setShowNotification(true);
            }
        }
        
    },[])
    useEffect(()=> {
        if(!Util.checkIsNullOrUndefined(towTruckLocationInfo) && towTruckLocationInfo.length > 0) {
            getDistanceMatrix();
        }
    },[towTruckLocationInfo,customerLocationInfo])
    useEffect(()=> {
        setInterval(() => {
            if(!Util.checkIsNullOrUndefined(guid) && !Util.checkIsNullOrUndefined(guid)) {
                getTowTruckLocation();
            }
        }, Constants.REFRESH_TIME_GET_TOW_TRUCK_LOCATION)
    },[])
    return(
        <div className="p-4">
            {showErrorToastMessage && errorToast(toastMessage)}
            <div className="container">
                <div className="flex-container">
                    {showNotification &&
                        <ActionNotification
                            message={t(Constants.CUSTOMER_LOCATION_PERMISSION_MESSAGE)}
                            permission={()=>
                                {
                                    getUserLocation();
                                    setShowNotification(false)
                                }
                            }
                            denied={()=> setShowNotification(false)}/>}
                </div>
                {isShowAlert ? (Alert.infoAlert(alertMessage)) :
                    <LoadScript id={"google-map-script"} googleMapsApiKey={API_KEY}>
                    <GoogleMaps
                        showTruckLocation={false}
                        towTruckLocationList={towTruckLocationInfo}
                        isLoading={isLoading}
                        setIsLoading={(state) => setIsLoading(state)}
                        towTruckPlate={towTruckPlate}
                        providerName={providerName}
                            distanceMatrix={distanceMatrix}
                            logoImage={logoImage}
                        showDistanceMatrix={showDistanceMatrix}
                    />
                    </LoadScript>}
            </div>
        </div>
    )
}
export {TowTruckLocationComponent}