import React, {useEffect, useState} from "react";
import "../../../index.css";
import { useFormik } from 'formik';
import ConstantStatusString from "../../../util/constantStatusString";
import Constants from "../../../util/constantStrings";
import { useTranslation } from "react-i18next";
function ServicesAdvanceSearch({searchParams, setSearchParams, onSearch, isCompleted}) {
    const { t } = useTranslation();
    const [statusList, setStatusList] = useState([]);
    const userRole = JSON.parse(localStorage.getItem("user")).role;
    const formik = useFormik({
        initialValues: {
            Fmprotocol: "",
            assigneePlate: "",
            customerCarPlate: "",
            serviceStatus: "",
            date: "",
            partner: ""
        },
        onSubmit: () => {
            onSearch(searchParams)
        },
        onReset: values => {
            const searchParams = {
                Fmprotocol: values.Fmprotocol,
                AssignPlate: values.assigneePlate,
                CustomerPlate: values.customerCarPlate,
                GeoStatusID: values.serviceStatus,
                partner: values.partner
            }
            setSearchParams(searchParams)
            onSearch()
        }
    });
    useEffect(()=>{
        const statusArray = Array.from(ConstantStatusString.STATUS_STRING_MAP, ([value, text]) => ({ value, text:t(text) }));
        const statusOptionArray = statusArray.filter(statusItem => isCompleted ? statusItem.value === Constants.SERVICE_GEO_STATUS_ID : statusItem.value !== Constants.SERVICE_GEO_STATUS_ID)
        setStatusList(statusOptionArray)
    },[])
  return (
   <div className="container advance-search-container">
      <form  onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <input
              id="Fmprotocol"
              name="Fmprotocol"
              type="number"
              placeholder= {t('LABEL.FILE_NUMBER')}
              className="advance-search-input"
              onChange={(event) => {
                  setSearchParams({
                      ...searchParams,
                      Fmprotocol: event.target.value
                  })
              }}
              value={searchParams?.ServiceId}
          />
          {userRole !== Constants.SUPERVISOR_ADMIN && <input
              id="assigneePlate"
              name="assigneePlate"
              type="text"
              className="advance-search-input"
              placeholder={t('LABEL.ASSIGNED_LICENSE_PLATE')}
              onChange={(event) => {
                  setSearchParams({
                      ...searchParams,
                      AssignPlate: event.target.value
                  })
              }}
              value={searchParams?.AssignPlate}
          />}
          {userRole === Constants.SUPERVISOR_ADMIN && <input
              id="partner"
              name="partner"
              type="text"
              className="advance-search-input"
              placeholder={t('COLUMN.PROVIDER')}
              onChange={(event) => {
                  setSearchParams({
                      ...searchParams,
                      Partner: event.target.value
                  })
              }}
              value={searchParams?.Partner}
          />}
          <input
              id="customerCarPlate"
              name="customerCarPlate"
              type="text"
              className="advance-search-input"
              placeholder={t('LABEL.INSURED_LICENSE_PLATE')}
              onChange={(event) => {
                  setSearchParams({
                      ...searchParams,
                      CustomerPlate: event.target.value
                  })
              }}
              value={searchParams?.CustomerPlate}
          />
          <select
              id="serviceStatus"
              name="serviceStatus"
              className="advance-search-input"
              placeholder={t('LABEL.STATUS')}
              onChange={(event) => {
                  setSearchParams({
                      ...searchParams,
                      GeoStatusID: parseInt(event.target.value)
                  })
              }}
              value={searchParams?.GeoStatusID}
          >
              <option value={""}>{t('LABEL.STATUS_OF_SERVICES')}</option>
              {statusList.map(statusItem => {
                  return (
                      <option value={statusItem.value} key={statusItem.value}>{statusItem.text}</option>
                  )
              })}
          </select>
          <button className="advance-search-filter-button" type="submit">{t('BUTTON.SEARCH')}</button>
          <button className="advance-search-reset-button" type="reset">{t('BUTTON.CLEAR')}</button>
      </form>
    </div>
  );
}

export default ServicesAdvanceSearch;
