import "./Footer.css";

export { Footer };

function Footer() {
  return (
    <>
      <div className="container-fluid footer-container">
        <p className="text-center">2022 © GeoLocation</p>
      </div>
    </>
  );
}
