import {useRecoilValue} from "recoil";
import {authAtom} from "../../state";
import {Redirect, Route} from "react-router-dom";
import RoleUtil from "../../util/utilityFunctions/RoleUtil";
export {SupervisorAdminRoute}
function SupervisorAdminRoute({component: Component, ...rest}){
    const auth = useRecoilValue(authAtom);
    return (
        <Route
            {...rest}
            render={(props) => {
                if (!auth) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname: "/account/login",
                                state: {from: props.location},
                            }}
                        />
                    );
                }else if(!RoleUtil.isSupervisorAdmin()) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {from: props.location},
                            }}
                        />
                    );
                }

                // authorized so return component
                return <Component {...props} />;
            }}
        />
    );
}