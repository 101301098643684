import React from "react";
import "index.css"
import ConstantStatusString from "../util/constantStatusString";
import moment from "moment";
import DateFormatUtil from "../util/utilityFunctions/DateFormatUtil";
import Util, {checkIsNullOrUndefined} from "../util/utilityFunctions/Util";
import Constants from "../util/constantStrings";
import { useTranslation } from "react-i18next";
function ProgressBar({ serviceStatusInfoList }) {
    const { t } = useTranslation();
    function getStatusById(id) {
        return t(ConstantStatusString.STATUS_STRING_MAP.get(id));
    }
    function getStatusRecordDate(index){
        const statusObject = Util.safeArrayElementAccess(serviceStatusInfoList, index, {});
        return statusObject?.recordDate;
    }
    function getTimeFromDate(index){
        const date = getStatusRecordDate(index);
        return DateFormatUtil.isDateValid(date) ?  moment(date).format("HH:mm") : "";
    }
    function getProgressStepStyleByStatusId(statusId){
        const lastStatusInfo = Util.getLastElementOfArray(serviceStatusInfoList, {});
        if(!checkIsNullOrUndefined(lastStatusInfo)) {
            if(statusId < lastStatusInfo.statusId) {
                return "progress-passed-step";
            }else if (statusId === lastStatusInfo.statusId) {
                return "progress-active-step";
            }else {
                return "progress-next-step";
            }
        } else {
            return "";
        }
    }
    function getProgressStepIconStyleByStatusId(statusId){
        const lastStatusInfo = Util.getLastElementOfArray(serviceStatusInfoList, {});
        if(!checkIsNullOrUndefined(lastStatusInfo)) {
            if(statusId < lastStatusInfo.statusId) {
                return "progress-passed-step-icon";
            }else if (statusId === lastStatusInfo.statusId) {
                return "progress-active-step-icon";
            }else {
                return "progress-next-step-icon";
            }
        } else {
            return "";
        }
    }
    function getProgressPercent(){
        if(!checkIsNullOrUndefined(serviceStatusInfoList) && serviceStatusInfoList.length > 0) {
            return (serviceStatusInfoList.length - 1) * Constants.PROGRESS_STEP_PERCENT;
        } else {
            return Constants.PROGRESS_ZERO_PERCENT;
        }

    }
    function getIncompleteProgressPercent(){
        return Constants.PROGRESS_HUNDRED_PERCENT - getProgressPercent();
    }
    function getStatusProgressIcon(progressId){
        return <i className={`${ConstantStatusString.STATUS_ICON_STRING_MAP.get(progressId)} align-bottom me-1 fa-lg ${getProgressStepIconStyleByStatusId(progressId)}`}/>

    }
    function getStatusProgressText(progressId) {
        return (
            <div className="mt-3" style={{width: '100px'}}>
                <strong className="progress-status-text">{getStatusById(progressId)}</strong>
            </div>
        )
    }
    function getStatusProgressTime(index) {
        return (
            <div style={{width: '100px'}}>
                <strong className="progress-time-text">{getTimeFromDate(index)}</strong>
            </div>
        )
    }
    function getStatusProgressNode(){
        const statusProgressList = Array.from(ConstantStatusString.STATUS_PROGRESS_STRING_MAP.values());
        return (
            <>
            {statusProgressList.map((item, index) => {
                return (
                    <div data-tooltip={`${DateFormatUtil.getDottedDMYByHourFormatDate(getStatusRecordDate(index))}`} className={`position-absolute top-0 translate-middle btn btn-sm btn-primary rounded-pill progress-item ${getProgressStepStyleByStatusId(item)}`} style={{left: `${index * 33}%`}} key={index}>
                        {getStatusProgressIcon(item)}
                        {getStatusProgressText(item)}
                        {getStatusProgressTime(index)}
                    </div>
                )
                })}
            </>
        )
    }
    return (
        <div className="position-relative m-5">
            <div className="progress progress-line">
                <div className="progress-bar progress-active-line" role="progressbar" style={{width: `${getProgressPercent()}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"/>
                <div className="progress-bar progress-passive-line" role="progressbar" style={{width: `${getIncompleteProgressPercent()}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"/>
            </div>
            {getStatusProgressNode()}
        </div>
    )
}
export default ProgressBar;