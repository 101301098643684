import axios from "axios";
import JwtService from "./JwtService";
import {history} from "_helpers/History";
const ApiService = {
    init(){
        axios.interceptors.request.use( request => {
            const token = JwtService.getToken()
            request.headers.Authorization =  token ? `Bearer ${token}` : '';
            return request;
        }, error => {
            return Promise.reject(error);
        });
        axios.interceptors.response.use(function (response) {
            return response;
        }, err => {
            if (err.response.status === 401) {
                history.push("/");
            }
            return Promise.reject(err);
        });
    }
}
export default ApiService;