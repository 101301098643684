import React, {
  useState,
  useRef,
  useMemo,
  useCallback, useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./CompleteServicesComponent.css";
import ConstantDataGridString from "../../../util/constantDataGridString";
import ConstantColorString from "../../../util/constantColorString";
import PagingUtil from "../../../util/utilityFunctions/PagingUtil";
import Constants from "../../../util/constantStrings";
import RoleUtil from "../../../util/utilityFunctions/RoleUtil";
import ServiceService from "../../../services/ServiceService";
import DateFormatUtil from "../../../util/utilityFunctions/DateFormatUtil";
import { BtnCellRenderer } from "../services/BtnCellRenderer";
import ConstantStatusString from "../../../util/constantStatusString";
import ServicesAdvanceSearch from "../services/ServicesAdvanceSearch";
import ErrorService from "../../../services/ErrorService";
import DataGridTooltip from "../services/DataGridTooltip";
import Util from "../../../util/utilityFunctions/Util";
import { useTranslation } from "react-i18next";
export { CompleteServicesComponent };

function CompleteServicesComponent() {
    const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [searchParams, setSearchParams] = useState();
  const gridRef = useRef();
  const userRole = JSON.parse(localStorage.getItem("user")).role;
  const dataSource = {
    rowCount: undefined,
    getRows: (params) => {
      const payload = {
        page:PagingUtil.computedPageFromParams(params),
        isCompleted: Constants.COMPLETED_SERVICE_CODE,
        ...searchParams
      }
      if(RoleUtil.isAdmin() || RoleUtil.isSupervisorAdmin()) {
        ServiceService.getServiceList(payload).then(response => {
          params.successCallback(response.data.data.services,response.data.data.pages * ConstantDataGridString.DATA_GRID_PAGE_SIZE)
        }).catch(error => {
          ErrorService.errorConsole(error);
          params.failCallback();
        })
      }else {
        ServiceService.getServiceListByProviderId(payload).then(response => {
          params.successCallback(response.data.data.services,response.data.data.pages * ConstantDataGridString.DATA_GRID_PAGE_SIZE)
        }).catch(error => {
          ErrorService.errorConsole(error);
          params.failCallback();
        })
      }
    }
  };
  const defaultColDef = useMemo(() => ({
    flex: 1,
    resizable: true,
    filterParams: {
      filterOptions: null,
    },
    tooltipComponent: DataGridTooltip,
  }));
  const cellClickedListener = useCallback((event) => {
  }, []);
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  function cellStyle(params) {
    return {color: ConstantColorString.GRID_COLOR_MAP.get(params.value)}
  }
  function onGridReady(params) {
    params.api.setDatasource(dataSource)
  }
  function getRowStyle(params) {
    if (params.node.rowIndex % 2 === 0) {
      return { background: ConstantColorString.DATA_GRID_ROW_EVEN_ROW };
    }
  }
  function getDataGridColumInfo(){
    return  [
      {
        headerName: t('COLUMN.FILE_NO'),
        field: "fmprotocol",
        resizable: true,
        suppressSizeToFit: false,
        minWidth: 70,
        maxWidth: 140,
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: t('COLUMN.ASSIGNED_LICENCE_PLATE'),
        field: "assignCarPlate",
        minWidth: 80,
        maxWidth: 150,
        hide: userRole === Constants.SUPERVISOR_ADMIN,
        cellRenderer: function (params) {
          return (
              <>
                {params?.value && <div className="plateDiv">
                      {window.localStorage.getItem('i18nextLng') === 'tr' ? < p className="plate">
                          <span>TR</span>
                          {Util.nullSafe(params?.value.toUpperCase())}
                      </p> : <p className="plate">{Util.nullSafe(params?.value.toUpperCase())}</p>}
                </div>}
              </>
          );
        },
      },
      {
        headerName: t('COLUMN.PROVIDER'),
        field: "partner",
        minWidth: 80,
        maxWidth: 150,
        hide: userRole !== Constants.SUPERVISOR_ADMIN,
      },
      {
        headerName: t('COLUMN.INSURED_LICENSE_PLATE'),
        field: "customerCarPlate",
        minWidth: 80,
        maxWidth: 150,
        cellRenderer: function (params) {
          return (
              <>
                {params?.value && <div className="plateDiv">
                      {window.localStorage.getItem('i18nextLng') === 'tr' ? < p className="plate">
                          <span>TR</span>
                          {Util.nullSafe(params?.value.toUpperCase())}
                      </p> : <p className="plate">{Util.nullSafe(params?.value.toUpperCase())}</p>}
                </div>}
              </>
          );
        },
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: t('COLUMN.DESCRIPTION'),
        field: "fdrnotes",
        minWidth: 80,
        maxWidth: 500,
        hide: windowSize.innerWidth <= 530,
        tooltipField: 'fdrnotes'
      },
      {
        headerName: t('COLUMN.STATUS'),
        field: "geoStatusId",
        minWidth: 125,
        maxWidth: 200,
        cellStyle: cellStyle,
          cellRenderer: function (params) {
          return <span><i className={`${ConstantStatusString.STATUS_ICON_STRING_MAP.get(params.value)} align-bottom me-1`}/>{t(ConstantStatusString.STATUS_STRING_MAP.get(params.value))}</span>
        },
      },
      {
        headerName: t('COLUMN.DATE'),
        field: "fddatecreated",
        minWidth: 80,
        maxWidth: 190,
        valueFormatter:(params)=>  DateFormatUtil.getDottedDMYByHourFormatDate(params.value),
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: t('COLUMN.LAST_STATUS_DATE'),
        field: "lastStatuDate",
        minWidth: 80,
        maxWidth: 190,
        valueFormatter:(params)=>  DateFormatUtil.getDottedDMYByHourFormatDate(params.value),
        hide: windowSize.innerWidth <= 530
      },
      {
        headerName: t('COLUMN.ACTIONS'),
        filter: false,
        field: "",
        maxWidth: 800,
        minWidth: 150,
        cellRenderer: function(params) {
          return (
              <>
                {params.node.data && <BtnCellRenderer isShowOnlyServiceDetail={true} gridRowData={params.node.data}/>}
              </>
          )
        },
        cellRendererParams: {
          clicked: function (field) {
            alert(`${field} was clicked`);
          },
        },
      },
    ]
  }
  function getDataGridOptions(){
    return{
      suppressRowHoverHighlight: true,
      columnHoverHighlight: false,
    }
  }
  function onSearch(){
    onGridReady(gridRef.current)
  }
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }
  useEffect(()=>{
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  },[])
  return (
      <div className="flex-container table-container">
        <div className="ag-theme-alpine react" style={{ height: 465 }}>
          <ServicesAdvanceSearch searchParams={searchParams} setSearchParams={(params)=> setSearchParams(params)} onSearch={(searchParams)=> onSearch(searchParams)} isCompleted={true}/>
          <AgGridReact
              {...getDataGridOptions()}
              rowClass={"grid-row"}
              getRowStyle={getRowStyle}
              ref={gridRef}
              rowModelType="infinite"
              columnDefs={getDataGridColumInfo()}
              cacheBlockSize={ConstantDataGridString.DATA_GRID_PAGE_SIZE}
              cacheOverflowSize={0}
              defaultColDef={defaultColDef}
              rowHeight={ConstantDataGridString.DATA_GRID_ROW_HEIGHT}
              onGridReady={onGridReady}
              onCellClicked={cellClickedListener}
              tooltipShowDelay={0}
              tooltipHideDelay={5000}
          />
        </div>
      </div>
  );
}
